/**
 * Converts a given text into a slug suitable for URLs.
 *
 * @param {string} text - The text to be slugified.
 * @returns {string} The slugified version of the input text.
 */
function slugify(text) {
  if (!text) return;
  return text
    .toLowerCase()
    .replace(/ /g, '-')
    .replace(/[/]+/g, '-')
    .replace(/[-]+/g, '-')
    .replace(/[^\w-]+/g, '');
}

function getFocusableElements(container) {
  return Array.from(
    container.querySelectorAll(
      "summary, a[href], button:enabled, [tabindex]:not([tabindex^='-']), [draggable], area, input:not([type=hidden]):enabled, select:enabled, textarea:enabled, object, iframe"
    )
  );
}

/**
 * Retrieves the global promo tag associated with the provided related tag.
 *
 * @param {string} relatedTag - The related tag to search for in the global promo tags.
 * @returns {Object | null} - The global promo tag object if found, or null if not found or an error occurs.
 */
function getGlobalPromoTag(relatedTag) {
  let globalPromoTags;
  try {
    globalPromoTags = JSON.parse(
      document.getElementById('promo-tag-manager-json').innerHTML
    ).promoTags;
  } catch (e) {
    console.warn("Can't set promo tags.");
    return null;
  }

  return globalPromoTags.find(tag => tag.relatedTag === relatedTag);
}

/**
 * Generates the HTML markup for a promo tag based on the provided promo tag object.
 *
 * @param {Object} promoTag - The promo tag object containing information such as textColor, backgroundColor, and label.
 * @returns {string} - The HTML markup for the promo tag.
 */
function getPromoTagMarkup(promoTag) {
  return `<div
    class="promo-tag"
    style="color: ${promoTag.textColor}; background-color: ${promoTag.backgroundColor};"
  >
    ${promoTag.label}
  </div>`;
}

function onKeyUpEscape(event) {
  if (event.code.toUpperCase() !== 'ESCAPE') return;

  const openDetailsElement = event.target.closest('details[open]');
  if (!openDetailsElement) return;

  const summaryElement = openDetailsElement.querySelector('summary');
  openDetailsElement.removeAttribute('open');
  summaryElement.setAttribute('aria-expanded', false);
  summaryElement.focus();
}

const trapFocusHandlers = {};

function trapFocus(container, elementToFocus = container) {
  var elements = getFocusableElements(container);
  var first = elements[0];
  var last = elements[elements.length - 1];

  removeTrapFocus();

  trapFocusHandlers.focusin = event => {
    if (
      event.target !== container &&
      event.target !== last &&
      event.target !== first
    )
      return;

    document.addEventListener('keydown', trapFocusHandlers.keydown);
  };

  trapFocusHandlers.focusout = function () {
    document.removeEventListener('keydown', trapFocusHandlers.keydown);
  };

  trapFocusHandlers.keydown = function (event) {
    if (event.code.toUpperCase() !== 'TAB') return; // If not TAB key
    // On the last focusable element and tab forward, focus the first element.
    if (event.target === last && !event.shiftKey) {
      event.preventDefault();
      first.focus();
    }

    //  On the first focusable element and tab backward, focus the last element.
    if (
      (event.target === container || event.target === first) &&
      event.shiftKey
    ) {
      event.preventDefault();
      last.focus();
    }
  };

  document.addEventListener('focusout', trapFocusHandlers.focusout);
  document.addEventListener('focusin', trapFocusHandlers.focusin);

  elementToFocus.focus();

  if (
    elementToFocus.tagName === 'INPUT' &&
    ['search', 'text', 'email', 'url'].includes(elementToFocus.type) &&
    elementToFocus.value
  ) {
    elementToFocus.setSelectionRange(0, elementToFocus.value.length);
  }
}

// Here run the querySelector to figure out if the browser supports :focus-visible or not and run code based on it.
try {
  document.querySelector(':focus-visible');
} catch (e) {
  focusVisiblePolyfill();
}

function focusVisiblePolyfill() {
  const navKeys = [
    'ARROWUP',
    'ARROWDOWN',
    'ARROWLEFT',
    'ARROWRIGHT',
    'TAB',
    'ENTER',
    'SPACE',
    'ESCAPE',
    'HOME',
    'END',
    'PAGEUP',
    'PAGEDOWN'
  ];
  let currentFocusedElement = null;
  let mouseClick = null;

  window.addEventListener('keydown', event => {
    if (navKeys.includes(event.code.toUpperCase())) {
      mouseClick = false;
    }
  });

  window.addEventListener('mousedown', event => {
    mouseClick = true;
  });

  window.addEventListener(
    'focus',
    () => {
      if (currentFocusedElement)
        currentFocusedElement.classList.remove('focused');

      if (mouseClick) return;

      currentFocusedElement = document.activeElement;
      currentFocusedElement.classList.add('focused');
    },
    true
  );
}

function removeTrapFocus(elementToFocus = null) {
  document.removeEventListener('focusin', trapFocusHandlers.focusin);
  document.removeEventListener('focusout', trapFocusHandlers.focusout);
  document.removeEventListener('keydown', trapFocusHandlers.keydown);

  if (elementToFocus) elementToFocus.focus();
}

function debounce(fn, wait) {
  let t;
  return (...args) => {
    clearTimeout(t);
    t = setTimeout(() => fn.apply(this, args), wait);
  };
}

function validatePhoneNumber(phone) {
  if (!phone) return;
  phone = phone.replace(/\s/g, ''); // remove all spaces
  const internationalRegex = /^\+(?:[0-9] ?){6,14}[0-9]$/;
  if (internationalRegex.test(phone)) {
    return phone.startsWith('+') ? phone : '+' + phone;
  }
  const usRegex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
  if (usRegex.test(phone)) {
    return '+1' + phone;
  }
  return false;
}

function validateEmail(email) {
  if (!email) return;
  const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  if (emailRegex.test(email)) {
    return true;
  }
  return false;
}

export {
  slugify,
  trapFocus,
  onKeyUpEscape,
  debounce,
  removeTrapFocus,
  getGlobalPromoTag,
  getPromoTagMarkup,
  validatePhoneNumber,
  validateEmail
};
